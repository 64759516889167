<template>
  <v-dialog v-model="dialog" persistent max-width="800">
    <v-card class="pb-5">
      <v-alert
        v-model="alert"
        color="primary_text"
        dense
        type="info"
        class="text-center black--text caption mb-0"
        dismissible
        >{{ $t("alertsendtest") }}</v-alert
      >

      <v-card-text>
        <v-row class="d-flex flex-column justify-center mt-1">
          <v-tabs v-model="tab" background-color="white" dark>
            <v-tab class="px-12" v-for="(item, index) in items" :key="item.tab">
              <v-btn
                v-if="index === 0"
                color="primary"
                dense
                @click="overlay = true"
                >{{ $t(item.tab) }}
              </v-btn>
              <v-btn
                v-else
                style="margin-left: -75%; background :#CCEBE9"
                color="primary"
                dense
                text
                @click="overlay = true"
                >{{ $t(item.tab) }}
              </v-btn>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-row class="d-flex justify-center" no-gutters>
                <v-col cols="12" class="px-9">
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                  <v-row class="py-4 px-6">
                    <span style="color: #000"
                      >{{ $t("candidade_imported_label_text") }}
                    </span>
                    <download-excel
                      class="btn btn-default"
                      :data="json_data"
                      :fields="json_fields"
                      worksheet="My Worksheet"
                      type="csv"
                      name="exemplo.csv"
                    >
                      <!-- <v-btn type="submit" color="success" tile>
                        Baixar
                      </v-btn> -->
                      <a type="submit" style="margin-left: 25%">
                        {{ $t("candidade_imported_label_1") }}
                      </a>
                    </download-excel>
                    <v-col
                      v-show="this.resultCandidates.length > 0"
                      cols="12"
                      md="6"
                      class="pl-12 pt-5 pb-0 mb-0"
                    >
                      <v-text-field
                        v-model="vacancyTitle"
                        :label="$t('jobTitle')"
                        outlined
                        hide-details=""
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <vue-dropzone
                v-if="resultCandidates.length <= 0"
                id="dropzone"
                :options="{
                  url: 'https://httpbin.org/post',
                  thumbnailWidth: 200,
                  addRemoveLinks: true,
                  dictDefaultMessage: $t('candidade_imported_label_drag'),
                }"
                ref="dropzone"
                @vdropzone-complete="startImport"
                @vdropzone-drop="startLoading"
                @vdropzone-file-added="startLoading"
              ></vue-dropzone>
              <v-list v-else class="mx-10" dense>
                <v-list-item
                  v-for="(candidate, index) in resultCandidates"
                  :key="candidate.email"
                  class="pb-0 mb-0"
                >
                  <v-list-item-icon>
                    <v-icon color="indigo"> mdi-account-circle </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      >{{ candidate.name }}
                      {{ candidate.lastName }}</v-list-item-title
                    >
                  </v-list-item-content>

                  <v-list-item-icon @click="removeCandidateImported(index)">
                    <v-icon color="error">mdi-close-circle</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-tab-item>
            <v-tab-item>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" md="12" class="pt-5 pb-0 mb-0">
                    <v-text-field
                      v-model="vacancyTitle"
                      :label="$t('jobTitle')"
                      outlined
                      hide-details=""
                      dense
                      :rules="requiredRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="candidate.name"
                      :label="$t('form_candidate_name')"
                      outlined
                      hide-details=""
                      dense
                      :rules="requiredRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="candidate.lastName"
                      :label="$t('form_candidate_surname')"
                      outlined
                      hide-details=""
                      dense
                      :rules="requiredRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="candidate.email"
                      label="Email"
                      outlined
                      hide-details=""
                      dense
                      :rules="emailRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="candidate.telephone"
                      :label="$t('form_user_phone')"
                      outlined
                      hide-details=""
                      dense
                      :rules="requiredRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </v-row>
        <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
          <v-btn
            text
            class="mx-5"
            color="primary"
            @click="$emit('close')"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            v-show="canMoveForward"
            :disabled="isLoading"
            color="primary"
            @click="advance()"
            >{{ $t("next") }}</v-btn
          >
        </v-row>
      </v-card-text>
    </v-card>
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
    <select-test
      :dialog="dialogTest"
      :vacancyTitle="vacancyTitle"
      :candidates="candidates"
      @close="closeDialogTest()"
    />
  </v-dialog>
</template>

<script>
import { IMPORT_CANDIDATES_MUTATION } from "../../candidate/graphql/Mutation";
import {
  CREATE_CANDIDATE_ENTITY_MUTATION,
  CREATE_INPORTED_CANDIDATES_MUTATION,
} from "./../../candidate/graphql/Mutation";
import { ADD_DIALOG } from "@/mixins/dialog";
import { formatError } from "@/utils";
import { mapActions } from "vuex";
import ErrorDialog from "../../../components/ErrorDialog.vue";
import ProgressDialog from "../../../components/ProgressDialog.vue";
import SuccessDialog from "../../../components/SuccessDialog.vue";
import vueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import SelectTest from "./SelectTest.vue";
export default {
  name: "AddCandidateTestDialog",
  components: {
    vueDropzone,
    ErrorDialog,
    ProgressDialog,
    SuccessDialog,
    SelectTest,
  },
  props: {
    dialog: Boolean,
  },
  mixins: [ADD_DIALOG],
  data: () => ({
    alert: true,
    candidate: {},
    candidates: [],
    dialogTest: false,
    dropzoneOptions: {
      url: "https://httpbin.org/post",
      thumbnailWidth: 200,
      addRemoveLinks: true,
      dictDefaultMessage:
        '<div class="v-card v-sheet theme--light grey lighten-3" style="height: 200px;">' +
        '<div class="text-center"><p class="text-center">Solte o ficheiro nesta área para importar</p>' +
        '<div><i aria-hidden="true" class="v-icon notranslate mb-5 v-icon--right mdi mdi-cloud-upload theme--light primary--text"></i></div>' +
        '<button type="button" class="v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default">' +
        '<span class="v-btn__content">importar ficheiro</span></button></div><p class="text-center pt-2 overline">.pdf .doc .docx .rtf</p></div>',
    },
    items: [
      { tab: "candidade_imported_csv", content: "Tab 1 Content" },
      { tab: "candidade_imported_cad_cand", content: "Tab 2 Content" },
    ],
    json_data: [
      {
        name: "xxxx",
        lastName: "xxxx",
        email: "xxxx@xxxx.xxx",
        telephone: "8000000000",
      },
    ],
    json_fields: {
      name: "name",
      lastName: "lastName",
      email: "email",
      telephone: "telephone",
    },
    overlay: true,
    resultCandidates: [],
    tab: null,
    vacancyTitle: "",
    valid: true,
  }),
  computed: {
    canMoveForward() {
      return (
        (this.resultCandidates.length > 0 || this.candidate.email) &&
        this.vacancyTitle !== ""
      );
    },
    emailRules() {
      return [
        (v) => !!v || "Campo obrigatório",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
    },
    requiredRules() {
      return [(v) => !!v || "Campo obrigatório"];
    },
    word1() {
      return {
        url: "https://httpbin.org/post",
        thumbnailWidth: 200,
        addRemoveLinks: true,
        dictDefaultMessage: this.$t("candidade_imported_label_drag"),
      };
    },
  },
  methods: {
    ...mapActions({
      setCandidateState: "candidate/setCandidate",
    }),
    async advance() {
      try {
        this.isLoading = true;
        if (this.tab === 0) {
          const { data } = await this.$apollo.mutate({
            mutation: CREATE_INPORTED_CANDIDATES_MUTATION,
            variables: {
              candidates: this.resultCandidates,
            },
          });
          data.createImportedCandidates.forEach((candidate) => {
            this.setCandidateState(candidate);
          });
          this.candidates = data.createImportedCandidates;
        } else {
          const { data } = await this.$apollo.mutate({
            mutation: CREATE_CANDIDATE_ENTITY_MUTATION,
            variables: {
              candidateInput: this.candidate,
            },
          });
          this.setCandidateState(data.createCandidateEntity);
          this.candidate = data.createCandidateEntity;
          // this.candidate.newCreated = true;
          this.candidates.push(Object.assign({}, this.candidate));
          this.candidate = {};
        }

        this.resultCandidates = [];
        this.dialogTest = true;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    closeDialogTest() {
      this.dialogTest = false;
      this.$emit("close");
    },
    removeCandidateImported(index) {
      this.resultCandidates.splice(index, 1);
    },
    async startImport() {
      try {
        const candidates = await this.$refs.dropzone.getAcceptedFiles()[0];
        const { data } = await this.$apollo.mutate({
          mutation: IMPORT_CANDIDATES_MUTATION,
          variables: {
            candidates,
          },
        });
        this.resultCandidates = data.importCandidates.filter((c) => {
          if (c) {
            return true;
          } else {
            return false;
          }
        });
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    startLoading() {
      this.isLoading = true;
    },
  },
};
</script>
<style>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
.dropzone {
  /* padding-top: 50px; */
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 0;
  border: none;
}
.dropzone .dz-message {
  margin: 0;
}
</style>